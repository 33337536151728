import React from "react"
import { Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"
import ContactUs from "../components/contact-us"

const WorkPage = ({ data: { workPost, pageContent } }) => {

    const work = workPost.edges
    const content = pageContent.edges

    return(
        <Layout>
            {content.map(page => ( 
                <SEO 
                title={page.node.SEOmeta.metaTitle} 
                description={page.node.SEOmeta.metaDescription}
                keywords={page.node.SEOmeta.metaKeywords}
                ogTitle={page.node.SEOmeta.ogTitle} 
                ogDescription={page.node.SEOmeta.ogDescription}
                ogImage={page.node.SEOmeta.ogImage.localFile.childImageSharp.fluid}
                twitterTitle={page.node.SEOmeta.twitterTitle} 
                twitterDescription={page.node.SEOmeta.twitterDescription}
                twitterImage={page.node.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
                />
            ))}
            <div style={{padding: '0 20px', }}>
                <TitleSection>
                    <h1>Our Work</h1>
                    <p>A custom approach for complex problems</p>
                </TitleSection>
                <MainLoop>
                    {work.map(post => (
                        <article
                        itemScope
                        itemType="http://schema.org/Article"
                        >
                            <Link to={`/work/${post.node.slug}/`} itemProp="url" >
                                <Img fluid={post.node.featuredImage.node.localFile.childImageSharp.fluid} alt={post.node.title} />
                            </Link>
                            <Link to={`/work/${post.node.slug}/`}><h2 dangerouslySetInnerHTML={{ __html: post.node.title }} itemProp="headline"/></Link>
                            {/* <p>Website, Ecommerce, Marketing Integration</p> */}
                        </article>
                    ))}
                </MainLoop>
                <ContactUs />
            </div>
        </Layout>
    );

}

const TitleSection = styled.header`
    max-width: 1320px;
    width: 100%;
    padding: 80px 70px;
    margin: 0 auto; 
    margin-top: 20px;
    margin-bottom: 60px;
    border: 10px solid #db4140;
    h1 {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 30px;
        font-weight: 400;
        color: #777c7c;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 45px;
      h1 {
        font-size: 60px;
      }
      p {
        font-size: 24px;
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      padding: 20px;
      border: 5px solid #db4140;
      margin-bottom: 20px;
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 18px;
        line-height: 1.4;
      }
    }
`

const MainLoop = styled.section`
    max-width: 1320px;
    width: 100%;
    margin: 70px auto;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    article {
        width: calc(50% - 15px);
        margin-bottom: 40px;
        a {
            text-decoration: none;
            color: #2b2520;
            > div {
                height: 475px;
                width: 100%;
                background-color: #aaa;
            }
        }
        h2 {
            font-family: "Nobel",sans-serif;
            font-size: 30px;
            line-height: 34px;
            font-weight: 700;
            color: #2b2520;
            margin-top: 10px;
            margin-bottom: 5px;
            transition-duration: .3s;
        }
        p {
            font-family: "Work Sans";
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: #444;
            margin-top: 0;
            margin-bottom: 0px;
        }
        img {
          transition: opacity 500ms ease 0s, transform 1s 0s !important;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          h2 {
            color: #db4140;
          }
        }
    }
    @media(max-width:1000px) {
      max-width: 700px;
      article {
        width: 100%;
      }
    }
    @media(max-width: 767px) {
      max-width: 500px;
      margin-top: 20px;
      article {
        a {
          > div {
            height: 275px;
          }
        }
        h2 {
          font-size: 24px;
          line-height: 1.2;
        }
      }
    }
`

export default WorkPage

export const pageQuery = graphql`
    query {
        workPost: allWpOurWorkProject(sort: {fields: date, order: DESC}) {
            edges {
                node {
                    title
                    slug
                    featuredImage {
                        node {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 750, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                      }
                }
            }
        }
        pageContent: allWpCustomPage(filter: {databaseId: {eq: 1252}}) {
            edges {
              node {
                SEOmeta {
                    metaTitle
                    metaDescription
                    metaKeywords
                    ogTitle
                    ogDescription
                    ogImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    twitterTitle
                    twitterDescription
                    twitterImage {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1320, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
              }
            }
          }
    }
`